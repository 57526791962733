import React from 'react'
import {Alert, Row} from 'reactstrap'

// Componente per la modifica di output_options del report. Per ora non è possibile modificare nulla, viene solo mostrato il percorso in cui verrà salvato il report su BQ
export default function OutputBox({ options }) {
  return (
    <Row className="justify-content-center">
      <Alert color="primary">
        Una volta eseguito il report, il risultato sarà disponibile su bigquery seguendo il percorso:{' '}
        <br/>
        <div className="text-center">
          <b>inn-ranking.{options.bigquery.dataset}.{options.bigquery.table}</b>
        </div>
      </Alert>
    </Row>
  )
}

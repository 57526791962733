import React, { useEffect, useState } from 'react'
import {Spinner} from 'react-bootstrap'
import {
  Col,
  FormGroup,
  Form,
  FormText,
  CardBody,
  Row,
  Button,
  Card,
  CardHeader,
  Input
} from "reactstrap";

import CharCheckModal from "../../components/molecules/CharCheckModal";
import GPTEstimateModal from "../../components/molecules/GPTEstimateModal";
import { api } from '../../utils/index'
import { ToastContainer } from 'react-toastify'
import { notify } from '../../utils/notify'

  const defaultPrompt = `Sei un SEO Copywriter esperto, devi individuare alcuni topic sui quali scrivere dei contenuti editoriali e relativo brief da fornire ai copy comprensivo di introduzione iniziale e idee di titolo per i paragrafi successivi con abstract per la scrittura, l'abstract dovrà essere fornito sia per l'introduzione sia per i paragrafi (almeno 3). L'abstract dovrà essere breve e conciso ma avere tutte le informazioni che sarà poi utile inserire nel testo finale, deve fungere da guida per chi scriverà il contenuto. Per permetterti di avere contesto e capire quali sono i contenuti che si posizionano ti darò in input il title, la description e gli heading tag presenti in pagina (sottotitoli di paragrafi) per le prime 10 posizioni di Google per la ricerca '{keyword}'. Razionalizza e riassumi le informazioni fornite, crea una bozza di topic sui quali posso scrivere contenuti legati al tema, esamina la bozza che hai fatto e recupera solamente i due topic più pertinenti al contesto che ti ho fornito.

Il risultato deve essere in formato JSON, più precisamente una lista di dizionari. Il JSON dovrà essere un TopicsArray (presentato come typescript interface):

\`\`\`
interface TopicsObject {
    topic:          string;  // titolo del topic
    introduction:     string;  // descrizione del topic
    abstract:      string; // indicazioni per i copy sui contenuti dell'articolo, devono essere 3 paragrafi ognuno con il proprio titolo 
}

interface TopicsArray {
    items: TopicsObject[];
}
\`\`\`

`

const PEDForm = () => {
  const [file, setFile] = useState(null)
  const [prompt, setPrompt] = useState(defaultPrompt)
  const [loading, setLoading] = useState(false)
  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)
  // Modal check gpt
  const [showGptCheck, setShowGptCheck] = useState(false)
  const [gptEstimateParams, setGptEstimateParams] = useState({
    n_keywords: -1,
    total_tokens: -1,
    estimate: -1,
  })
  const [acceptedGptCheck, setAcceptedGptCheck] = useState(false)

  const handleSubmit = event => {
    if (event)
      event.preventDefault()

    let formData = new FormData()
    formData.append('file', file)
    formData.append('prompt', prompt)
    formData.append('gpt_estimate_accepted', acceptedGptCheck)
    //formData.append('keyword_error_accepted', acceptedCharCheck)

    if(file==null){
      notify(
        'File mancante',
        'error'
      )
      return
    }
    setLoading(true)
    api
      .post('ped/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (typeof response.data !== 'undefined') {
          notify(
            "Richiesta mandata. Riceverai una mail al termine dell'elaborazione",
            'success'
          )
        } else {
          notify(
            'Richiesta fallita. Qualcosa è andato storto, riprovare o contattare un Innovation',
            'error'
          )
        }
        setAcceptedCharCheck(false)
        setAcceptedGptCheck(false)
        setLoading(false)
        setFile(null)
      })
      .catch(err => {
        if (err?.response?.data) {
          if (err.response.data?.message && err.response.data?.message.includes('ERROR Encoding keywords')) {
            setErrCharList(err.response.data?.errors);
            setShowCharCheck(true);
          }
          else if (err.response.data?.message && err.response.data?.message.includes('GPT Estimate')) {
            setGptEstimateParams(err.response.data?.estimates)
            setShowGptCheck(true);
          }
          else {
						notify(
              err.response.data,
              'error'
            )
            console.log(err.response.data)
            setLoading(false)
            setFile(null)
            setAcceptedCharCheck(false)
            setAcceptedGptCheck(false)
					}
        } else {
          setLoading(false)
          setFile(null)
          setAcceptedCharCheck(false)
          setAcceptedGptCheck(false)
        }
      })
  }

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

  const cancelEvaluation = () => {
    setShowCharCheck(false)
    setAcceptedGptCheck(false)
    setLoading(false)
  }

  const confirmEstimate = (e) => {
    e.preventDefault()
    setShowGptCheck(false)
    setAcceptedGptCheck(true)
  }

  const cancelEstimate = () => {
    setShowGptCheck(false)
    setShowCharCheck(false)
    setLoading(false)
  }

  useEffect(() => {
    if(acceptedCharCheck) {
      handleSubmit()
    }
  }, [acceptedCharCheck])

  useEffect(() => {
    if(acceptedGptCheck) {
      handleSubmit()
    }
  }, [acceptedGptCheck])

  return (
    <>
      <Row>
        <div className="col pb-5">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row style={{ marginLeft: "0px" }}>
                <Col md={10}>
                  <h1 className="mb-0">PED Generator</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CharCheckModal show={showCharCheck} onHide={cancelEvaluation} onSubmit={confirmEvaluation} errCharList={errCharList} withExclusion={true} />
              <GPTEstimateModal show={showGptCheck} onHide={cancelEstimate} onSubmit={confirmEstimate} params={gptEstimateParams} />
              {loading ? (
                <Spinner animation="grow" />
              ) : (
                <Form onSubmit={handleSubmit} id="idea-form" action="">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="file" style={{ display: 'block' }}>
                          File
                        </label>
                        <Input
                          className="form-control-alternative"
                          id={'file'}
                          type={'file'}
                          style={{ border: '0' }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          required
                          onChange={event => {
                            setFile(event.target.files[0])
                          }}
                        />
                        <FormText color="muted" align={'left'}>
                          Carica un file contenente le tue keywords da verificare.{' '}
                          <br />
                          <b>
                            Il file deve contenere la colonna "Keyword"
                          </b>
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label htmlFor="promp" className="form-control-label">
                          [BETA] Prompt Personalizzato
                        </label>
                        <Input
                          className="form-control-alternative"
                          style={{maxHeight: 400}}
                          type="textarea"
                          id="volume"
                          value={prompt}
                          placeholder="Inserisci un prompt personalizzato"
                          onChange={e => setPrompt(e.target.value)}
                          required
                        />
                        <FormText color="muted" align={'left'}>
                          Inserisci il prompt da passare a GPT, deve contenere la wildcard {"{keyword}"} nel punto in cui il software deve inserire la keyword
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{display: "flex", justifyContent: "flex-start", paddingLeft: 11}}>
                    <Button color="info" size="lg" onClick={handleSubmit} >Invia</Button>
                  </Row>
                </Form>
              )}
              <ToastContainer />
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  )

}

export default PEDForm

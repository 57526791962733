import React from 'react'
import {Row, Col, Input} from 'reactstrap'
import Select from 'react-select'

// Componete che mira a modificare parametri direttamente campi del modello Report, quali nome e gruppi associati
export default function SettingsBox({
  nome,
  setNome,
  nomeError,
  setNomeError,
  gruppi,
  gruppiOptions,
  setGruppi,
  lingua,
  setLingua,
}) {
  const lingue = ['Italiano', 'Inglese']

  return (
    <Row className="align-items-baseline">
      <Col>
        <label className={'vertical'}>Nome</label>
        <Input
          className="form-control-alternative"
          type="text"
          id="name"
          value={nome}
          isInvalid={nomeError}
          placeholder="Inserisci nome"
          onChange={(e) => {
            setNomeError(false)
            setNome(e.target.value)
          }}
        />
      </Col>
      <Col>
        <label className={'vertical'}>Lingua Tagging</label>
        <Select
          options={lingue.map((g) => {
            return { label: g, value: g }
          })}
          placeholder={'Seleziona una lingua'}
          className="single-select"
          classNamePrefix="react-select"
          value={
            // Per dare le lingue in pasto alla react select, li mappo in oggetti del tipo { label, value }
            { label: lingua || lingue[0], value: lingua || lingue[0] }
          }
          onChange={(e) => {
            setLingua(e.value)
          }}
          isMulti={false}
          isSearchable={false}
        />
      </Col>
      <Col xl={6}>
        <label className={'vertical'}>Gruppi</label>
        <Select
          options={gruppiOptions}
          placeholder={'Seleziona un gruppo'}
          className="single-select"
          classNamePrefix="react-select"
          value={
          // Per dare i gruppi in pasto alla react select, li mappo in oggetti del tipo { label, value }
            gruppi.map((g) => {
              return { label: g.name, value: g.name }
            })
        }
          onChange={(e) => {
            if (e.length === 0) setGruppi([])
            else
              setGruppi(
                e.map((g) => {
                  return { name: g.value }
                })
              )
          }}
          isMulti={true}
          isSearchable={true}
        />
      </Col>
    </Row>
  )
}

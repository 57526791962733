import React from 'react'
import {Row, Col, Input} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-solid'

// Componente per la modifica del campo input_options del report
export default function InputBox({
                                   client,
                                   setClient,
                                   language,
                                   setLanguage,
                                   file,
                                   fileMode,
                                   setFile,
                                   setFileMode,
                                   editFile,
                                   setEditFile,
                                   reportFile,
                                 }) {
  return (
    <Row className="align-items-baseline">
      <Col>
          <label className={'vertical'}>Cliente</label>
          <Input
            type="text"
            id="client"
            value={client}
            placeholder="Inserisci cliente"
            onChange={(e) => setClient(e.target.value)}
          />
      </Col>
      <Col>
          <label className={'vertical'}>Codice lingua</label>
          <Input
            type="text"
            id="language_code"
            value={language}
            placeholder="Inserisci codice lingua"
            onChange={(e) => setLanguage(e.target.value)}
          />
      </Col>
      <Col xl={6}>
          <label>File</label>
          {
            // Per editare il file è necessario cliccare sull'icona specifica
            editFile ? (
              <>
                  <Input
                    id={'file'}
                    type={'file'}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) =>
                      e.target.files ? setFile(e.target.files[0]) : setFile(null)
                    }
                  />
                  <Input
                    type="select"
                    style={{ display: 'inline', width: '40%' }}
                    defaultValue={fileMode}
                    onChange={(e) => setFileMode(e.target.value)}
                  >
                    <option value={'w'}>Sovrascrivi</option>
                    <option value={'a'}>Integra</option>
                  </Input>
              </>
            ) : (
              <p>
                {reportFile.split('/').pop()}{' '}
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => setEditFile(true)}
                />
              </p>
            )
          }
      </Col>
    </Row>
  )
}
